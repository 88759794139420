<template lang="en">
<html lang="en" class="notranslate" translate="no">
<!-- All translators -->

<head>
    <meta name="google" content="notranslate" />
    <!-- Just for google -->
</head>
<div class="app">
    <Menu :list="menuList" />

    <body>
        <div class="mx-auto">
            <div class="row">
                <Loading :isLoading="isLoading" />
                <div class="flex-1 px-0 py-0 m-0">
                    <div class="flex h-34 grid grid-cols-1 lg:grid-cols-1 lg:grid-cols-2 lg:grid-cols-2;">
                        <div class="collapse dont-collapse-sm">
                            <img src="@/assets/imagen-registro.png" class="img-responsive min-h-full" loading="lazy" />
                        </div>
                        <div class="center sm:mx-20">
                            <div class="col-span-1"></div>
                            <div class="col-span-10 py-16">
                                <p class="title pt-4 ml-5 text-left">Crear cuenta</p>
                                <p class="ml-5 text-left RobotoRegular text-lg" style="color: #726659">
                                    Ingrese sus datos y comience a formar parte de FONCABSA
                                </p>
                                <form @submit.prevent="send" class="px-5 pt-3 mb-4 text-left">

                                    <div class="form-group mt-4">
                                        <p class="text-left mt-3 mb-1 RobotoLight text-base" style="color: #8e857a">
                                            RFC
                                        </p>
                                        <input v-model="client.rfc" @input="validateRFC()" v-maska="'AAAA######XXX'" type="text" class="RobotoRegular shadow appearance-none border rounded w-full py-2.5 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline uppercase" id="Input_RfcClient" />
                                        <div class="input-errors" v-for="error of validations.rfc.$errors" :key="error.$uid">
                                            <div class="text-red-600 mt-2">{{ error.$message }}</div>
                                        </div>
                                    </div>
                                    <div class="form-group mt-4">
                                        <p class="text-left mt-3 mb-1 RobotoLight text-base" style="color: #8e857a">
                                            Apellido paterno
                                        </p>
                                        <input v-model="client.paternal_name" type="text" class="RobotoRegular shadow appearance-none border rounded w-full py-2.5 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline" id="Input_PaternalClient"/>
                                        <div class="input-errors" v-for="error of validations.paternal_name.$errors" :key="error.$uid">
                                            <div class="text-red-600 mt-2">{{ error.$message }}</div>
                                        </div>
                                    </div>
                                    <div class="form-group mt-4">
                                        <p class="text-left mt-3 mb-1 RobotoLight text-base" style="color: #8e857a">
                                            Apellido materno
                                        </p>
                                        <input v-model="client.maternal_name" type="text" class="RobotoRegular shadow appearance-none border rounded w-full py-2.5 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline" id="Input_MaternalClient"/>
                                        <div class="input-errors" v-for="error of validations.maternal_name.$errors" :key="error.$uid">
                                            <div class="text-red-600 mt-2">{{ error.$message }}</div>
                                        </div>
                                    </div>
                                    <div class="form-group mt-4">
                                        <p class="text-left mt-3 mb-1 RobotoLight text-base" style="color: #8e857a">
                                            Primer nombre
                                        </p>
                                        <input v-model="client.name" type="text" class="RobotoRegular shadow appearance-none border rounded w-full py-2.5 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline" id="Input_FirstNamesClient" />
                                        <div class="input-errors" v-for="error of validations.name.$errors" :key="error.$uid">
                                            <div class="text-red-600 mt-2">{{ error.$message }}</div>
                                        </div>
                                    </div>
                                    <div class="form-group mt-4">
                                        <p class="text-left mt-3 mb-1 RobotoLight text-base" style="color: #8e857a">
                                            Segundo nombre
                                        </p>
                                        <input v-model="client.middle_name" @input="validateRFC()" type="text" class="RobotoRegular shadow appearance-none border rounded w-full py-2.5 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline" id="Input_SecondNamesClient" />
                                        <div class="input-errors" v-for="error of validations.middle_name.$errors" :key="error.$uid">
                                            <div class="text-red-600 mt-2">{{ error.$message }}</div>
                                        </div>
                                    </div>
                                    <div class="form-group mt-4">
                                        <p class="text-left mt-3 mb-1 RobotoLight text-base" style="color: #8e857a">
                                            Correo electrónico
                                        </p>
                                        <input v-model="client.email" type="text" class="RobotoRegular shadow appearance-none border rounded w-full py-2.5 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline" id="Input_EmailClient" />
                                        <div class="input-errors" v-for="error of validations.email.$errors" :key="error.$uid">
                                            <div class="text-red-600 mt-2">{{ error.$message }}</div>
                                        </div>
                                    </div>
                                    <div class="form-group mt-4">
                                        <p class="text-left mt-3 mb-1 RobotoLight text-base" style="color: #8e857a">
                                            Contraseña
                                        </p>
                                        <div class="input-group" style="margin-top: -5px">
                                            <input v-model="client.password" type="password" class="RobotoRegular shadow appearance-none border rounded w-full py-2.5 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline" id="Input_Password" />
                                            
                                            <span tabIndex="-1" class="input-group-addon input-show-password"><button type="button" tabIndex="-1" v-on:click="changeIcoPassword()">
                                                    <div tabIndex="-1" id="divImageHidePasswordOn">
                                                        <img id="imageHidePasswordOn" src="@/assets/visibility-off.svg" width="20" height="20" style="margin-right: 18px" />
                                                    </div>
                                                    <div tabIndex="-1" id="divImageHidePasswordOff" style="visibility: collapse">
                                                        <img tabIndex="-1" id="imageHidePasswordOff" src="@/assets/visibility-on.svg" width="0" height="0" style="margin-right: 18px" />
                                                    </div>
                                                </button>
                                            </span>
                                        </div>
                                        <div class="input-errors" v-for="error of validations.password.$errors" :key="error.$uid">
                                            <div class="text-red-600 mt-2">{{ error.$message }}</div>
                                        </div>
                                    </div>
                                    <div class="form-group mt-4">
                                        <p class="text-left mt-3 mb-1 RobotoLight text-base" style="color: #8e857a">
                                            Confirmar contraseña
                                        </p>
                                        <div class="form-group" style="margin-top: -5px">
                                            <input v-model="client.confirm_password" type="password" class="RobotoRegular shadow appearance-none border rounded w-full py-2.5 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline" id="Input_PasswordConfirm" />

                                            <span tabIndex="-1" class="input-group-addon input-show-password"><button type="button" tabIndex="-1" v-on:click="changeIcoPasswordConfirm()">
                                                    <div tabIndex="-1" id="divImageHidePasswordConfirmOn">
                                                        <img id="imageHidePasswordConfirmOn" src="@/assets/visibility-off.svg" width="20" height="20" style="margin-right: 18px" />
                                                    </div>
                                                    <div tabIndex="-1" id="divImageHidePasswordConfirmOff" style="visibility: collapse">
                                                        <img tabIndex="-1" id="imageHidePasswordConfirmOff" src="@/assets/visibility-on.svg" width="0" height="0" style="margin-right: 18px" />
                                                    </div>
                                                </button></span>
                                        </div>
                                        <div class="input-errors" v-for="error of validations.confirm_password.$errors" :key="error.$uid">
                                            <div class="text-red-600 mt-2">{{ error.$message }}</div>
                                        </div>
                                    </div>
                                    <div class="mt-3 mb-1">
                                        <button id="RegisterButton" class="text-white mt-5 rounded RobotoRegular">
                                            Registro
                                        </button>
                                    </div>
                                </form>
                                <br />
                            </div>
                            <div class="col-span-1"></div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </body>

    <Footer />

</div>

</html>
</template>

<script>
import("@/assets/css/stylesheet.css");

import {
    useRouter, useRoute
} from "vue-router";
import {
    ref,
    reactive,
    onBeforeMount,
    onMounted,
    watch,
    computed
} from "vue";

import Referral from "@/classes/Referral";
import Client from "@/classes/Client";
import Menu from "@/components/Menu.vue";
import Footer from "@/components/Footer3.vue";
import Loading from "@/components/Loading/Loadingc.vue"
import Swal from "sweetalert2";

import {
    setBirthday,
    validateMatchRFCwithName,
    validityRFC,
    referenceIsAvailable,
} from "@/api/user";

import menuOptions from "@/helpers/menu.js";

import useVuelidate from "@vuelidate/core";
import {
    requiredIf,
    email,
    helpers,
    minLength,
    maxLength,
} from '@vuelidate/validators'

export default {
    name: "RegistroCliente",
    setup() {
        const referralInstance = new Referral();
        const clientInstance = new Client({});

        let isLoading = ref(false);
        let organization = ref('');
        let reference = ref('');
        let nameReference = ref('');
        let referalId = ref('');
        let birthdate = reactive('');
        let client = reactive({
            rfc: '',
            name: '',
            middle_name: '',
            paternal_name: '',
            maternal_name: '',
            email: '',
            password: '',
            confirm_password: ''
        });
        let passwordHide = reactive(false);
        let confirmPasswordHide = reactive(false); 
        let isValidStructure = reactive(true);
        let isValidMatchWithFullName = reactive(true);
        let isClientInformationIsCorrect = reactive(false);
        let clientInformation = reactive({
                user: {
                    email: "",
                    password: "",
                    person_attributes: {
                        0: {
                            rfc: "",
                            paternal_name: "",
                            maternal_name: "",
                            name: "",
                            middle_name: "",
                            state: "",
                            city: "",
                            status: "activo"
                        },
                    },
                },
                organization: "",
                url: "",
                reference: "",
                referal_id: ""
        });
        
        const rules = reactive({
            rfc: {
                required: helpers.withMessage('El campo RFC es requerido.', requiredIf(true)),
                minLength: helpers.withMessage('El mínimo de caracteres ingresados deben ser 13.', minLength(13)),
                maxLength: helpers.withMessage('El máximo de caracteres ingresados deben ser 13.', maxLength(13)),
                $autoDirty: true
            },
            name: {
                required: helpers.withMessage('El campo Primer nombre es requerido.', requiredIf(true)),
                minLength: helpers.withMessage('El mínimo de caracteres ingresados deben ser 2.', minLength(2)),
                maxLength: helpers.withMessage('El máximo de caracteres ingresados deben ser 30.', maxLength(30)),
                $autoDirty: true
            },
            middle_name: {
                required: helpers.withMessage('El campo Segundo nombre es requerido.', requiredIf(false)),
                minLength: helpers.withMessage('El mínimo de caracteres ingresados deben ser 3.', minLength(3)),
                maxLength: helpers.withMessage('El máximo de caracteres ingresados deben ser 30.', maxLength(30)),
                $autoDirty: true
            },
            paternal_name: {
                required: helpers.withMessage('El campo Apellido paterno es requerido.', requiredIf(true)),
                minLength: helpers.withMessage('El mínimo de caracteres ingresados deben ser 2.', minLength(2)),
                maxLength: helpers.withMessage('El máximo de caracteres ingresados deben ser 30.', maxLength(30)),
                $autoDirty: true
            },
            maternal_name: {
                required: helpers.withMessage('El campo Apellido materno es requerido.', requiredIf(true)),
                minLength: helpers.withMessage('El mínimo de caracteres ingresados deben ser 2.', minLength(2)),
                maxLength: helpers.withMessage('El máximo de caracteres ingresados deben ser 30.', maxLength(30)),
                $autoDirty: true
            },
            email: {
                required: helpers.withMessage('El campo Correo electrónico es requerido.', requiredIf(true)),
                minLength: helpers.withMessage('El mínimo de caracteres ingresados deben ser 10.', minLength(10)),
                email: helpers.withMessage('El formato del Correo electrónico no es el correcto.', email),
                $autoDirty: true
            },
            password: {
                required: helpers.withMessage('El campo Contraseña es requerido.', requiredIf(true)),
                minLength: helpers.withMessage('El mínimo de caracteres ingresados deben ser 8.', minLength(8)),
                maxLength: helpers.withMessage('El máximo de caracteres ingresados deben ser 30.', maxLength(30)),
                $autoDirty: true
            },
            confirm_password: {
                required: helpers.withMessage('El campo Confirmar contraseña es requerido.', requiredIf(true)),
                minLength: helpers.withMessage('El mínimo de caracteres ingresados deben ser 8.', minLength(8)),
                maxLength: helpers.withMessage('El máximo de caracteres ingresados deben ser 30.', maxLength(30)),
                sameAsPassword: helpers.withMessage('El campo Confirmar contraseña no coincide con Contraseña.', () => {
                    return client.password == client.confirm_password;
                }),
                $autoDirty: true
            },
        });

        const validations = useVuelidate(rules, client);

        const router = useRouter();
        const route = useRoute();
        const menuList = ref([]);
        
        onBeforeMount(async () => {
            await getMenuOptionsList();
        });

        onMounted(() => {
            loadParameters();
            if (referalId.value == ""){
                referenceIsAvailable()
            }else{
                openReferalLink();
            }
        });

        const loadParameters = () => {
            if(route.query.reference != undefined){
                reference.value = route.query.reference;
                console.info("Info: reference", route.query.organization);
            }
            
            if(route.query.referal_id != undefined){
                referalId.value = route.query.referal_id;
                console.info("Info: referal_id", route.query.referal_id);
            }
            
            if(route.params.organization != undefined){
                organization.value = route.params.organization;
                organization.value = organization.value == 'sinaptissste'? 'issste': organization.value;
                console.info("Info: organization", route.params.organization);
                console.info("Info: organization", organization.value);
            }
        }

        const validateRFC = async () => {
            await validateRFCStructure();
        }

        const getMenuOptionsList = async () => {
            await menuOptions.getMenuOptions().then((resp) => {
                menuList.value = resp.data.FONCABSA;
            }).catch((err) => {
                console.error("Error:", err);
            });
        }

        const send = async () => {
            isLoading.value = true;
            validations.value.$validate();
            await validateMatchRFC();

            if(validations.value.$error) {
                return;
            }

            if(!isValidMatchWithFullName) {
                isLoading.value = false;
                
                await Swal.fire({
                    title: "Aviso",
                    text: "Sus apellidos y nombre(s) no concuerdan con su rfc. ¿Los datos son correctos?",
                    icon: "warning",
                    showCancelButton: true,
                    confirmButtonColor: '#FEB72B',
                    cancelButtonColor: '#FEB72B',
                    confirmButtonText: "Si",
                    cancelButtonText: "No, Quiero corregir mis datos."
                }).then((result) => {
                    isClientInformationIsCorrect = result.value ;
                });

                if(isClientInformationIsCorrect){
                    await Swal.fire({
                        title: "Aviso",
                        text: "No podemos validar su rfc con los datos que ingresó, puede solicitar apoyo para su registro comunicándose" +
                            " a las oficinas de FONCABSA al número: (644) 416-4346",
                        icon: "warning",
                        confirmButtonColor: '#FEB72B',
                    });
                }
                
                return;
            }

            await register().finally(()=>{
                isLoading.value = false;
            });
        }

        const register = async () => {

            clientInformation.organization = organization.value;
            clientInformation.url = process.env.VUE_APP_URLWEB+"/login?reference=";
            clientInformation.reference = reference.value;
            clientInformation.referal_id = referalId.value;

            clientInformation.user.email = client.email.toLowerCase().trim();
            clientInformation.user.password = client.password.trim();
            clientInformation.user.person_attributes = {
                0: {
                    rfc: client.rfc.trim(),
                    paternal_name: client.paternal_name.trim(),
                    maternal_name: client.maternal_name.trim(),
                    name: client.name.trim(),
                    middle_name: client.middle_name.trim(),
                    state: '',
                    city: '',
                    status: "activo"
                },
            };

            await clientInstance.signUp(clientInformation).then(response => {
                Swal.fire({
                    title: "Aviso",
                    text:"Se registró correctamente. Para activar su cuenta deberá de entrar a la liga que se le envió al correo proporcionado, si no lo encuentra favor de buscar en correo no deseado.",
                    icon: "success",
                    confirmButtonColor: '#FEB72B',
                }).then(() => {
                    router.push({path:"/login"});
                });

            }).catch(err => {
                const messageError = err.response.data.errores.join("</br>")
                Swal.fire({
                    title: "Aviso",
                    html: `<span style="font-weight: bold; color:red">${messageError}</span>`,
                    icon: "error",
                    confirmButtonColor: '#FEB72B',
                });
            });
        }

        const changeIcoPassword = () => {
            if (passwordHide) {
                document
                .getElementById("Input_Password")
                .setAttribute("type", "password");
                document.getElementById("divImageHidePasswordOff").style.visibility =
                "collapse";
                document.getElementById("imageHidePasswordOff").width = "0";
                document.getElementById("imageHidePasswordOff").height = "0";
                document.getElementById("divImageHidePasswordOn").style.visibility =
                "visible";
                document.getElementById("imageHidePasswordOn").width = "20";
                document.getElementById("imageHidePasswordOn").height = "20";
                passwordHide = false;
            } else {
                document.getElementById("Input_Password").setAttribute("type", "text");
                document.getElementById("divImageHidePasswordOn").style.visibility =
                "collapse";
                document.getElementById("imageHidePasswordOn").width = "0";
                document.getElementById("imageHidePasswordOn").height = "0";
                document.getElementById("divImageHidePasswordOff").style.visibility =
                "visible";
                document.getElementById("imageHidePasswordOff").width = "20";
                document.getElementById("imageHidePasswordOff").height = "20";
                passwordHide = true;
            }
        }

        const changeIcoPasswordConfirm = () => {
            if (confirmPasswordHide) {
                document
                .getElementById("Input_PasswordConfirm")
                .setAttribute("type", "password");
                document.getElementById(
                "divImageHidePasswordConfirmOff"
                ).style.visibility = "collapse";
                document.getElementById("imageHidePasswordConfirmOff").width = "0";
                document.getElementById("imageHidePasswordConfirmOff").height = "0";
                document.getElementById(
                "divImageHidePasswordConfirmOn"
                ).style.visibility = "visible";
                document.getElementById("imageHidePasswordConfirmOn").width = "20";
                document.getElementById("imageHidePasswordConfirmOn").height = "20";
                confirmPasswordHide = false;
            } else {
                document
                .getElementById("Input_PasswordConfirm")
                .setAttribute("type", "text");
                document.getElementById(
                "divImageHidePasswordConfirmOn"
                ).style.visibility = "collapse";
                document.getElementById("imageHidePasswordConfirmOn").width = "0";
                document.getElementById("imageHidePasswordConfirmOn").height = "0";
                document.getElementById(
                "divImageHidePasswordConfirmOff"
                ).style.visibility = "visible";
                document.getElementById("imageHidePasswordConfirmOff").width = "20";
                document.getElementById("imageHidePasswordConfirmOff").height = "20";
                confirmPasswordHide = true;
            }
        }

        async function validateRFCStructure() {
            
            if(client.rfc.length == 13){
                isValidStructure = await validityRFC(client.rfc).then((response) => {
                    return response.data.response.rfc_is_valid
                }).catch((error) => {
                    console.error("Error:", error);
                    Swal.fire({
                        title: "Aviso",
                        html: error.response.data.errors.length > 0 ? error.errors.map(msj => msj) : error.response.data.response.message,
                        icon: "error",
                        confirmButtonColor: '#FEB72B',
                    });
                    return false;
                });

                if(isValidStructure){
                    birthdate = await getBirthdayOfRFC(client.rfc);
                }
            }
        }

        async function validateMatchRFC() {
            
            if(client.rfc == '' || client.name == '' || client.paternal_name == '' || client.maternal_name == '' || birthdate == ''){
                isValidMatchWithFullName = true;
                return true;
            }

            isValidMatchWithFullName = await validateMatchRFCwithName(
                client.rfc.toUpperCase(),
                birthdate,
                client.name.trim(),
                client.middle_name.trim(),
                client.paternal_name.trim(),
                client.maternal_name.trim()
            ).then((response) => {
                return true;
            }).catch((error) => {
                console.error("Error:", error);
                return false;
            });
        }

        const getBirthdayOfRFC = async(rfc) => {
            return await setBirthday(rfc).then((response) => {
                return response.data.response.date;
            }).catch((error) => {
                console.error("Error: ", error);
                return '';
            }).finally(() => {
                isLoading.value = false;
            });
        }

        const referenceIsAvailable = async() => {
            if(reference.value != ""){
                var resp = false;
                await referenceIsAvailable(reference.value).then((response) => {
                    resp = !!response.data.response.available
                    nameReference.value = response.data.response.name_referece
                    if(!resp){
                        Swal.fire({
                        title: response.data.response.message,
                        text: "¿Desea continuar con el registro sin referencia?",
                        showDenyButton: true,
                        confirmButtonText: `Si`,
                        denyButtonText: `No`,
                        confirmButtonColor: '#FEB72B',
                        denyButtonColor: '#FEB72B',
                        }).then((result) => {
                            if (result.isConfirmed) {
                                router.push({path:"/registro"});
                            } else if (result.isDenied) {
                                router.push({path:"/"});
                            }
                        })
                    }else{
                        checkRegistrationPage(nameReference.value, reference.value)
                    }
                }).catch((error) => {
                }).finally(() => {
                })
            }
        }

        const checkRegistrationPage = (name_reference, reference) => {
            if(!["member","cabsa_member"].includes(name_reference)){
                router.push({path:`personal/registro?reference=${reference}`});
            }
        }

        const openReferalLink = () => {
            referralInstance.openReferalLink(referalId.value).then(response => {
                console.info("Info:","link abierto");
            }).catch(error => {
            });
        }
        
        return {
            menuList,
            validations,
            client,
            send,
            passwordHide,
            confirmPasswordHide,
            changeIcoPassword,
            changeIcoPasswordConfirm,
            isLoading,
            birthdate,
            validateRFC,
        };
    },
    components: {
        Menu,
        Footer,
        Loading,
    },
};
</script>

<style scoped>
.center {
    padding: 00px 0;
    border: 3px solid #ffffff;
    text-align: center;
}

@media only screen and (max-width: 1000px) {
    .collapse {
        display: none;
    }
}

.button_popups {
    border-top-left-radius: 15px !important;
    border-top-right-radius: 15px !important;
    border-bottom-left-radius: 15px !important;
    border-bottom-right-radius: 15px !important;
    background-color: #feb72b !important;
    width: 250px !important;
    height: 30px !important;
    padding-top: 0% !important;
}

button {
    outline: none !important;
}

input[type="text"]:focus-visible,
input[type="email"]:focus-visible,
input[type="number"]:focus-visible,
input[type="password"]:focus-visible,
textarea:focus-visible {
    border: 2px solid #810042;
    outline: none;
}

#RegisterButton {
    height: 50px;
    width: 100%;
    background-color: #ffcc00;
    border: 1px solid #ffcc00;
    transition: all 300ms;
}

#RegisterButton:hover {
    background: #ad8a00;
    border: 1px solid #ad8a00;
}

.input-show-password {
    position: absolute;
    margin-left: -40px;
    margin-top: 10px;
}
</style>
